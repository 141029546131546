import { useState } from 'react';

import { Thesis } from '../../../models/training/Thesis';

import { PracticeContainerRight } from './PracticeContainerRight';
import { PracticeQuestions } from './PracticeQuestions';
import { RateQuestion } from './questions/RateQuestion';

type Props = {
  thesis: Thesis;
  nextThesis: () => void;
  incrementQuestionCounter: () => void;
  shouldAlignCenter: boolean;
  encodedSubjectName: string;
  lastQuestionId?: string;
};

export const PracticeQA = ({
  thesis,
  nextThesis,
  incrementQuestionCounter,
  shouldAlignCenter,
  encodedSubjectName,
  lastQuestionId,
}: Props) => {
  const [showRating, setShowRating] = useState(false);

  const finishThesis = () => {
    setShowRating(false);
    incrementQuestionCounter();
    nextThesis();
  };

  return (
    <PracticeContainerRight shouldAlignCenter={shouldAlignCenter}>
      <PracticeQuestions
        thesis={thesis}
        incrementQuestionCounter={incrementQuestionCounter}
        encodedSubjectName={encodedSubjectName}
        lastQuestionId={lastQuestionId}
        showRating={() => {
          setShowRating(true);
        }}
      />

      {showRating && (
        <RateQuestion
          thesisId={thesis._id}
          onNextQuestionClick={finishThesis}
        />
      )}
    </PracticeContainerRight>
  );
};
