import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { Thesis } from '../../../models/training/Thesis';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { PracticeContainerLeft } from './PracticeContainerLeft';

type Props = {
  thesis: Thesis;
  shouldAlignCenter: boolean;
  isDictationQuestion: boolean;
};

export const PracticeThesis = ({
  thesis,
  shouldAlignCenter,
  isDictationQuestion,
}: Props) => {
  const { subjectColor } = useSubjectColorContext();

  return (
    <PracticeContainerLeft shouldAlignCenter={shouldAlignCenter}>
      {thesis.exam && <SubTitle>{thesis.exam}</SubTitle>}
      {thesis.title && (
        <Title
          color={subjectColor}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(thesis.title),
          }}
        />
      )}

      {isDictationQuestion && (
        <Dictation
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(thesis.questions[0].translation ?? ''),
          }}
        />
      )}

      <Introduction
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(thesis.html),
        }}
      />
    </PracticeContainerLeft>
  );
};

const Dictation = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleBig}
`;
const Title = styled.h1<{ color: string }>`
  color: ${({ color }) => color};
  ${TextStyles.TitleBig}
`;
const SubTitle = styled.h1`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.TitleMedium}
`;
const Introduction = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.InputText}
`;
