import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { ZIndex } from '../../../constants/Zindex';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CloseIcon } from '../../Icons/CloseIcon';

import { ReportQuestion } from './ReportQuestion';

type Props = {
  amountOfQuestions: number;
  currentQuestionIndex: number;
  showQuestionAmount: boolean;
  thesisId?: string;
};

export const HeaderRight = ({
  amountOfQuestions,
  currentQuestionIndex,
  showQuestionAmount,
  thesisId = '',
}: Props) => {
  const t = useTranslations();
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const { showModal } = useModal();

  const exit = () => {
    showModal({
      type: ModalTypes.ExitTrainingModal,
    });
  };

  return (
    <Container>
      <IconWrapper onClick={exit}>
        <CloseIcon />
      </IconWrapper>
      {showQuestionAmount && (
        <>
          <Title>
            {t('question')} ({currentQuestionIndex}/{amountOfQuestions})
          </Title>
          {!isDesktop && <ReportQuestion thesisId={thesisId} />}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  background-color: ${Colors.backgroundColor};
  z-index: ${ZIndex.z10};
  border-bottom: 1px solid ${Colors.dividerColor};

  @media ${HookMediaQueries.isDesktop} {
    position: initial;
  }
`;
const Title = styled.h1`
  flex: 1;
  text-align: center;
  ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  padding-left: 20px;
  cursor: pointer;
`;
