import { MathJaxContext } from 'better-react-mathjax';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import '@schedule-x/theme-default/dist/index.css';

import { MATHJAX_DEFAULT_CONFIG } from './constants/Mathjax';
import { TranslationsProvider } from './context/TranslationsProvider';
import { Root } from './pages/Root';
import { SocketProvider } from './socket/SocketProvider';
import { store, persistor } from './store/store';

function App() {
  return (
    <MathJaxContext config={MATHJAX_DEFAULT_CONFIG}>
      <TranslationsProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <SocketProvider>
                <Root />
              </SocketProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </TranslationsProvider>
    </MathJaxContext>
  );
}

export default App;
