import styled from '@emotion/styled';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { SubjectColorProvider } from '../../../context/SubjectColorContext';
import { Results } from '../../../models/Results';
import { useGetSubjectByEncodedNameQuery } from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';

import { ResultHeaderLeft } from './ResultHeaderLeft';
import { ResultHeaderRight } from './ResultHeaderRight';
import { ResultOverview } from './ResultOverview';
import { ResultTopicsComponent } from './ResultTopicsComponent';

type Props = {
  encodedSubjectName: string;
  practiceResult: Results | undefined;
};

export const Result = ({ encodedSubjectName, practiceResult }: Props) => {
  const { data: subject } = useGetSubjectByEncodedNameQuery(encodedSubjectName);

  return (
    <Container>
      <SubjectColorProvider subjectColor={subject?.color || ''}>
        <Left>
          <ResultHeaderRight />
          <ResultOverview result={practiceResult} />
        </Left>
        <Right>
          <>
            <ResultHeaderLeft />
            {practiceResult && (
              <ResultTopicsComponent topics={practiceResult.questionTopics} />
            )}
          </>
        </Right>
      </SubjectColorProvider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 2;
  flex-grow: 2;
  flex-shrink: 2;

  background-color: ${Colors.modalBackgroundColor};
  min-height: 100vh;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
  }
`;
const Left = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;

  @media ${HookMediaQueries.isDesktop} {
    flex: 1;
  }
`;
const Right = styled.div`
  flex: 1;
`;
