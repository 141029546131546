import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';

import { ProductBundle } from '../../models/ProductBundle';
import { Error } from '../../services/baseApi';
import { useLazyGetDiscountQuery } from '../../services/paymentApi';
import { useAppDispatch } from '../../store/hooks';
import { addDiscount } from '../../store/slices/cartSlice';
import { RootState } from '../../store/store';
import { CardBase } from '../common/CardBase';
import { InputFieldComponent } from '../common/Input';
import { DiscountDetails } from '../DiscountDetails';

import { FormData, FormFields, DiscountFormSchema } from './DiscountFormSchema';

export const DiscountCard = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const cart = useSelector((state: RootState) => state.cart);
  const [getDiscount] = useLazyGetDiscountQuery();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(DiscountFormSchema),
    reValidateMode: 'onChange',
  });

  const { ref: discountCodeRef, ...discountCodeRegister } = register(
    FormFields.discountCode
  );

  const onDiscountSubmit = async (discountCode: string) => {
    if (!discountCode) return;

    try {
      const products = cartItems.map((product: ProductBundle) => product._id);

      const discount = await getDiscount({
        code: discountCode,
        productBundles: products,
      }).unwrap();

      dispatch(
        addDiscount({
          code: '',
          amount: parseInt((discount?.discount ?? '0').toString(), 10),
          description: discount?.description || '',
        })
      );
    } catch (err) {
      const error = err as Error;
      switch (error?.status) {
        case 404:
          setErrorMessage(t('discount_not_found'));
          break;
        case 409:
          setErrorMessage(t('discount_used'));
          break;
        default:
          setErrorMessage(t('discount_unknown_error'));
      }
    }
  };
  const onSubmit = (data: { discountCode: string }) => {
    onDiscountSubmit(data.discountCode);
  };

  const setErrorMessage = (messageKey: string) => {
    setError(FormFields.discountCode, {
      type: 'manual',
      message: messageKey,
    });
  };

  return (
    <CardBase fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldComponent
          label={t('discount_code')}
          type="text"
          hasError={errors.discountCode !== undefined}
          errorText={errors?.discountCode?.message}
          showAcceptButton
          {...discountCodeRegister}
          inputRef={discountCodeRef}
        />
        <DiscountDetails price={cart.price} />
      </form>
    </CardBase>
  );
};
