import { useNavigate } from 'react-router-dom';

import { useError } from '../../../../hooks/useError';
import { User } from '../../../../models/User';
import {
  usePostSocialLoginMutation,
  Error,
} from '../../../../services/userApi';
import { useAppDispatch } from '../../../../store/hooks';
import {
  setSocialLoginProvider,
  setToken,
} from '../../../../store/slices/userSlice';
import { SocialProvider } from '../../../../utils/socialLoginUtils';
import { useDevice } from '../../hooks/useDevice';
import { SocialUser } from '../SocialUser';

export const useSocialLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const device = useDevice();
  const [loginUser] = usePostSocialLoginMutation();
  const { showErrorModal } = useError();

  const loginToAPI = async (socialUser: SocialUser): Promise<Error | void> => {
    try {
      const body = {
        ...socialUser,
        accessToken:
          socialUser.provider === SocialProvider.apple
            ? undefined
            : socialUser.token,
        code:
          socialUser.provider === SocialProvider.apple
            ? socialUser.serverAuthCode
            : undefined,
        device,
      };

      const result = await loginUser({ provider: socialUser.provider, body });

      if (result.error) throw result.error;

      if (result.data?.token.token) {
        handleSuccessfulLogin(result.data, socialUser.provider);
      }
    } catch (error) {
      showErrorModal(error);
    }
  };

  const handleSuccessfulLogin = (user: User, provider: SocialProvider) => {
    dispatch(setSocialLoginProvider(provider));
    dispatch(setToken(user.token));
    navigate('/');
  };

  return { loginToAPI };
};
