import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { Colors } from '../../../styles/colors';

type Props = {
  shouldAlignCenter: boolean;
} & PropsWithChildren;

export const PracticeContainerLeft = ({
  children,
  shouldAlignCenter,
}: Props) => (
  <Container shouldAlignCenter={shouldAlignCenter}>{children}</Container>
);

const Container = styled.div<{ shouldAlignCenter: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ shouldAlignCenter }) =>
    shouldAlignCenter ? 'center' : 'flex-start'};
  padding: 72px 12.5%;
  margin-top: ${HEADER_HEIGHT_PX}px;
  background-color: ${Colors.backgroundColor};

  @media ${HookMediaQueries.isDesktop} {
    height: calc(100vh - 144px - ${HEADER_HEIGHT_PX}px);
    overflow-y: auto;
    margin-top: 0;
  }
`;
