import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useGetNextUnreadSectionsQuery,
  useGetSubjectByEncodedNameQuery,
} from '../../services/subjectsApi';
import { PrimaryHeaderText } from '../../styles/elements/PrimaryHeaderText';
import { SubjectColorButton } from '../common/Button';
import { CardBase } from '../common/CardBase';
import { SubjectImage } from '../common/SubjectImage';

export const ContinueLearningCard = () => {
  const t = useTranslations();
  const { data: nextUnreadSections } = useGetNextUnreadSectionsQuery();

  const selectedSection = nextUnreadSections?.length
    ? nextUnreadSections[0]
    : undefined;

  const { data: subject } = useGetSubjectByEncodedNameQuery(
    selectedSection?.encodedSubjectName ?? skipToken
  );

  const navigate = useNavigate();

  const onButtonPress = () => {
    if (selectedSection) {
      navigate(
        `/subject/${selectedSection.encodedSubjectName}/summary?section=${selectedSection.encodedSectionName}`
      );
    }
  };

  if (!selectedSection) {
    return <></>;
  }

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<PrimaryHeaderText>{t('continue_learning')}</PrimaryHeaderText>}
    >
      <Container>
        <SubjectDetailsContainer>
          <SubjectImage src={subject?.subjectImage} />
          <SubjectTextContainer>
            <div>
              <strong>{subject?.name}</strong>
            </div>
            <div>{selectedSection.sectionTitle}</div>
          </SubjectTextContainer>
        </SubjectDetailsContainer>
        <SubjectButtonContainer>
          <StyledSubjectColorButton
            buttonSize="md"
            color={selectedSection.subjectColor}
            onClick={onButtonPress}
          >
            {t('continue_learning_section')}
          </StyledSubjectColorButton>
        </SubjectButtonContainer>
      </Container>
    </CardBase>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
`;

const SubjectDetailsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SubjectTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubjectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
`;

const StyledSubjectColorButton = styled(SubjectColorButton)<{ color: string }>`
  background-color: ${({ color }) => color};
  text-wrap: nowrap;
`;
