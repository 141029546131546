import {
  ApiTagTypes,
  COURSE_LEVEL_SUBJECTS_ENDPOINT,
  COURSE_LEVELS_ENDPOINT,
  REGISTER_ENDPOINT,
  RESEND_EMAIL_VERIFICATION_ENDPOINT,
} from '../constants/api';
import { CourseLevel } from '../models/CourseLevel';
import { StudentType } from '../models/StudentType';
import { Subject } from '../models/Subject';
import { RegisterBody } from '../models/user-management/RegisterBody';

import { baseApi } from './baseApi';

export const onboardingApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.Subjects],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      [COURSE_LEVELS_ENDPOINT]: build.query<CourseLevel[], void>({
        query: () => ({
          url: `/courseLevels`,
          method: 'GET',
        }),
      }),
      [COURSE_LEVEL_SUBJECTS_ENDPOINT]: build.query<
        Subject[],
        { courseLevelEncoded: string; categories: string[] }
      >({
        query: ({ courseLevelEncoded, categories }) => ({
          url: `/courseLevel/subjects`,
          method: 'GET',
          params: {
            courseLevel: courseLevelEncoded,
            categories: categories.join(','),
          },
        }),
        providesTags: [ApiTagTypes.Subjects],
      }),
      getSubjectsSections: build.query<Subject[], { subjectIds: string }>({
        query: ({ subjectIds }) => ({
          url: `/v2/courseLevel/subjects/sections?${subjectIds}`,
          method: 'GET',
        }),
        providesTags: [ApiTagTypes.Subjects],
      }),
      getStudentTypes: build.query<StudentType[], void>({
        query: () => ({
          url: `/student-types`,
          method: 'GET',
        }),
      }),
      [REGISTER_ENDPOINT]: build.mutation<void, RegisterBody>({
        query: (body) => ({
          url: `/user/register`,
          method: 'POST',
          body,
        }),
      }),
      [RESEND_EMAIL_VERIFICATION_ENDPOINT]: build.mutation<
        void,
        { email: string }
      >({
        query: (body) => ({
          url: `/user/register/mail/resend`,
          method: 'POST',
          body,
        }),
      }),
    }),
  });

export const {
  useGetCourseLevelsQuery,
  useGetSubjectsByCourseLevelQuery,
  useLazyGetSubjectsByCourseLevelQuery,
  useGetSubjectsSectionsQuery,
  useGetStudentTypesQuery,
  useRegisterMutation,
  useResendEmailVerificationMutation,
} = onboardingApi;
