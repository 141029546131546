import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import {
  SUMMARY_HEADER_HEIGHT,
  SUMMARY_SIDE_MENU_WIDTH,
} from '../../../constants/Sizes';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { Section, Sections, Video } from '../../../models/Summary/summary';
import { useGetSubjectByEncodedNameQuery } from '../../../services/subjectsApi';
import { useGetUserQuery } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { MenuIcon } from '../../Icons/Menu';
import {
  getUserReadSummaryTutorial,
  setUserReadSummaryTutorial,
} from '../../modals/SummaryTutorialModal';

import { useSummaryData } from './hooks/useSummaryData';
import { useTrackingEvent } from './hooks/useTrackingEvent';
import { SideMenuSummary } from './SideMenuSummmary';
import { SummaryContent } from './SummaryContent';
import { SummaryHeader } from './SummaryHeader';
import { hasActivePracticeModule, SummaryMode } from './utils/summaryUtils';

type Props = {
  summary: Sections;
};

export const MODE_SEARCH_PARAM = 'mode';

export const SummaryIndex = ({ summary }: Props) => {
  const { data: user } = useGetUserQuery();
  const { showModal } = useModal();
  const { encodedSubjectName } = useParams();
  const [searchParams] = useSearchParams();
  const querySection = searchParams.get(MODE_SEARCH_PARAM);
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  useEffect(() => {
    if (user && !getUserReadSummaryTutorial(user)) {
      showModal({
        type: ModalTypes.SummaryTutorialModal,
      });
      setUserReadSummaryTutorial(user);
    }
  }, [user]);

  useTrackingEvent();

  const { data: subject } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );

  const [isNavActive, setIsNavActive] = useState(false);

  const onNextSection = () => {
    setActiveVideo(current.videos[0]);
  };

  const { current, onSelectNextTopicClick, getNextSection, navigateToSection } =
    useSummaryData({
      summary,
      onNextSection,
    });

  const [activeVideo, setActiveVideo] = useState<Video | undefined>(
    current.videos[0]
  );

  useEffect(() => {
    setActiveVideo(current.videos[0]);
  }, [current]);

  const [summaryMode, setSummaryMode] = useState<SummaryMode>(
    querySection === SummaryMode.Video ? SummaryMode.Video : SummaryMode.Summary
  );

  const onChapterOrSectionPress = (section: Section) => {
    navigateToSection(section);
    if (!isDesktop) {
      setIsNavActive(false);
    }
  };
  const handleOptionChange = (option: SummaryMode) => {
    setSummaryMode(option);
  };

  const isPracticeModeActive = hasActivePracticeModule(
    current.section,
    summary
  );

  return (
    <Container>
      <MenuIconContainer>
        <MenuIcon onClick={() => setIsNavActive(!isNavActive)} />
      </MenuIconContainer>
      <SummaryHeader
        isNavActive={isNavActive}
        current={current}
        subjectColor={subject?.color ?? ''}
        isPreview={summary.previewExercise && !current.section.preview}
        isPracticeModuleActive={isPracticeModeActive}
      />
      <SubContainer>
        <ScrollableSideMenu isNavActive={isNavActive}>
          <SideMenuSummary
            isNavActive={isNavActive}
            chapters={summary?.chapters}
            subjectColor={subject?.color}
            videosActice={summary.videosActive}
            handleOptionChange={handleOptionChange}
            onChapterOrSectionPress={onChapterOrSectionPress}
            current={current}
            summaryMode={summaryMode}
          />
        </ScrollableSideMenu>

        <ScrollableContent>
          <SummaryContent
            subject={subject}
            summary={summary}
            summaryMode={summaryMode}
            activeVideo={activeVideo}
            setActiveVideo={setActiveVideo}
            current={current}
            hasNextSection={!!getNextSection()}
            onSelectNextTopicClick={onSelectNextTopicClick}
            isPracticeModeActive={isPracticeModeActive}
          />
        </ScrollableContent>
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  /* padding-top: env(safe-area-inset-top); */
  background-color: ${Colors.modalBackgroundColor};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-top: env(safe-area-inset-top);
  height: calc(100vh - env(safe-area-inset-top));
  background-color: ${Colors.summaryBackgroundColor};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 20px 0 20px;
  height: ${SUMMARY_HEADER_HEIGHT};
  z-index: 20;
  position: absolute;
  top: env(safe-area-inset-top);
  /* padding-top: env(safe-area-inset-top); */
`;
const ScrollableSideMenu = styled.div<{ isNavActive: boolean }>`
  display: flex;
  width: ${({ isNavActive }) => (isNavActive ? SUMMARY_SIDE_MENU_WIDTH : '0')};
  height: 100%;
  overflow-y: auto;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    width: 0%;
    height: 0;
  }
`;
const ScrollableContent = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  margin-top: ${SUMMARY_HEADER_HEIGHT};
  overflow-y: auto;
  transition: margin-left 0.3s ease;
`;
