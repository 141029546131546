import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { ResultTopics } from '../../../models/Results';
import { useGetSummarySectionsQuery } from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SubjectColorButton } from '../../common/Button';
import { PracticeContainerRight } from '../practice/PracticeContainerRight';

import { ResultTopicRow } from './ResultTopicRow';

type Props = {
  topics: ResultTopics[];
};

export const ResultTopicsComponent = ({ topics }: Props) => {
  const t = useTranslations();
  const [searchParams] = useSearchParams();
  const { subjectColor } = useSubjectColorContext();
  const { encodedSubjectName } = useParams();
  const { showModal } = useModal();
  const { data: sections } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );

  const getEncodedChapterNameFromSection = (encodedSectionName: string) => {
    if (!sections) return;
    const chapter = sections.chapters.find((chapter) =>
      chapter.sections.some(
        (chapterSection) =>
          encodedSectionName === chapterSection.encodedSectionName
      )
    );
    return chapter?.encodedChapterName;
  };

  const onTakeAnotherTestPress = () => {
    const encodedSectionName = searchParams.get('section');
    if (!encodedSectionName) return;

    const encodedChapterName =
      getEncodedChapterNameFromSection(encodedSectionName);
    if (!encodedChapterName) return;

    showModal({
      type: ModalTypes.StartTrainingModal,
      data: {
        encodedSubjectName,
        encodedSectionName,
        encodedChapterName,
      },
    });
  };

  return (
    <PracticeContainerRight shouldAlignCenter={false}>
      <TopicsContainer>
        <Topics>{t('topics')}</Topics>
        {topics.map((topic) => (
          <ResultTopicRow topic={topic} key={topic.encodedTopicName} />
        ))}
      </TopicsContainer>
      <SubjectColorButton
        buttonSize="md"
        buttonColor={subjectColor}
        onClick={onTakeAnotherTestPress}
        fullWidth={false}
      >
        {t('take_another_test')}
      </SubjectColorButton>
    </PracticeContainerRight>
  );
};

const TopicsContainer = styled.div`
  height: 90%;
`;

const Topics = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleBig}
`;
