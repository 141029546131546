import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../constants/routes';
import { UserRole } from '../../models/UserRole';
import { useGetUserQuery } from '../../services/userApi';
import { useAppDispatch } from '../../store/hooks';
import {
  clearUserManagementState,
  setAccountType,
  setCourseLevel,
  setTeacherCourseLevels,
} from '../../store/slices/userManagementSlice';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';

import { ProfileCardHeader } from './ProfileCardHeader';

export const ProfileEducationLevel = () => {
  const { data: user } = useGetUserQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const courseLevel = user?.courseLevel || user?.teacherCourseLevels.join(', ');

  const handleEditEducationLevel = () => {
    if (!user) return;
    dispatch(clearUserManagementState());

    dispatch(setAccountType(user.accountType));
    if (user.accountType === UserRole.teacher) {
      dispatch(setTeacherCourseLevels(user.teacherCourseLevels));
    } else {
      dispatch(setCourseLevel(user?.courseLevel));
    }
    navigate(appRoutes.ProfileChangeEducationLevel);
  };

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <ProfileCardHeader
          titleTranslationKey="education_level"
          buttonTranslationKey="edit_education_level"
          onButtonClick={handleEditEducationLevel}
        />
      }
    >
      <Text>{courseLevel}</Text>
    </CardBase>
  );
};

const Text = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyMedium}
  padding: 20px;
  margin: 0;
`;
