import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { MediaQueries } from '../../../constants/MediaQueries';
import { CardBase } from '../../common/CardBase';
import { ChevronRight } from '../../Icons/ChevronRight';

export const SelectableExamPeriodComponent = ({
  onPress,
  examPeriod,
}: {
  onPress: () => void;
  examPeriod: number;
}) => {
  const t = useTranslations();
  return (
    <StyledCardBase onClick={onPress}>
      <Container>
        <span>{t('school_period', { count: examPeriod })}</span>
        <StyledChevronRight />
      </Container>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0 20px 0 0;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MediaQueries.desktop}) {
    flex: 1;
    margin: 0 16px 0 0;
    border-radius: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
  width: 16px;
`;
