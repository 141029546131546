import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { mainRoutes } from '../../constants/routes';
import { Colors } from '../../styles/colors';
import { PrimaryButton } from '../common/Button';

import { SubjectsSubmenu } from './SubjectsDropdown';

export const NavButtons = () => {
  const t = useTranslations();

  return (
    <>
      {mainRoutes.map(({ path, titleKey }) => (
        <StyledNavLink key={path} to={path}>
          {({ isActive }) => {
            return (
              <>
                <NavButton
                  isSelected={isActive}
                  buttonSize="md"
                  textSize="sm"
                  onClick={(e) => titleKey === 'subjects' && e.preventDefault()}
                >
                  {t(titleKey)}
                  {titleKey === 'subjects' && <SubjectsSubmenu />}
                </NavButton>
              </>
            );
          }}
        </StyledNavLink>
      ))}
    </>
  );
};

const NavButton = styled(PrimaryButton)<{ isSelected?: boolean }>`
  ${({ isSelected }) =>
    !isSelected &&
    `
    background-color: ${Colors.primaryButtonContentColor}; 
    border: none; 
    color: ${Colors.secondaryButtonContentColor};

     &:hover {
      background-color: ${Colors.outlineBackgroundButton};
      }
    `}
  position:relative
`;

const StyledNavLink = styled(NavLink)`
  .subjectsMenu {
    height: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;
  }

  :hover {
    .subjectsMenu {
      opacity: 1;
      visibility: visible;
    }
  }
`;
