import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';

import { useError } from '../../hooks/useError';
import { ModalTypes, useModal } from '../../hooks/useModal';
import {
  DeliveryAddress,
  PaymentStatus,
  UserShipment,
} from '../../models/payments';
import { ProductBundle } from '../../models/ProductBundle';
import { useCreatePaymentMutation } from '../../services/cartApi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setPaymentStatus, setPaymentId } from '../../store/slices/cartSlice';

const DEFAULT_ORDER_DESCRIPTION = 'ExamenOverzicht in-app betaling';
const DEFAULT_CURRENCY = 'EUR';
const PAYMENT_LINK_METHOD = 'payment_link';
const CAPACITOR_WEB = 'web';

export const usePayment = () => {
  const [createPayment] = useCreatePaymentMutation();
  const cart = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showErrorModal } = useError();
  const { showModal } = useModal();

  const platform = Capacitor.getPlatform();
  const deepLinkScheme = process.env.REACT_APP_DEEP_LINK_SCHEME;
  const redirectUrl =
    platform === CAPACITOR_WEB ? window.location.origin : deepLinkScheme;

  const generatePaymentPayload = (
    deliveryAddress: DeliveryAddress,
    userShipment: UserShipment,
    selectedPaymentOptionId?: string
  ) => {
    return {
      amount: {
        value: cart.price.grandTotal.toFixed(2).toString(),
        currency: DEFAULT_CURRENCY,
      },
      description: DEFAULT_ORDER_DESCRIPTION,
      method: selectedPaymentOptionId,
      order: {
        deliveryAddress,
        bundles: cart.items.map((product: ProductBundle) => product._id),
        user: userShipment,
      },
      redirectUrl: `${redirectUrl}/payment/status`,
      //TODO https://digitalclub.atlassian.net/jira/software/c/projects/EXO/boards/13?selectedIssue=EXO-4111
      // discountCode: cartThing.discount ? cartThing?.discount?.code : '',
    };
  };

  const submitPayment = async (
    deliveryAddress: DeliveryAddress,
    userShipment: UserShipment,
    paymentMethodId?: string
  ) => {
    const paymentPayload = generatePaymentPayload(
      deliveryAddress,
      userShipment,
      paymentMethodId
    );

    try {
      const paymentResponse = await createPayment(paymentPayload).unwrap();
      if (!paymentResponse?.payment?.id) return;

      dispatch(setPaymentStatus(PaymentStatus.open));
      dispatch(setPaymentId(paymentResponse.payment.id));
      if (
        paymentResponse?.payment?.method === PAYMENT_LINK_METHOD &&
        paymentResponse?.payment?.paymentLink
      ) {
        showModal({
          type: ModalTypes.PaymentLinkModal,
          data: {
            paymentLink: paymentResponse?.payment?.paymentLink,
          },
        });
        return;
      }

      if (paymentResponse?.payment?._links.checkout.href) {
        window.location.href = paymentResponse?.payment?._links.checkout.href;
      }
    } catch (err) {
      showErrorModal(err);
    }
  };
  return { submitPayment };
};
