import { ApiTagTypes } from '../constants/api';
import { Sections } from '../models/Summary/summary';

import { baseApi } from './baseApi';

export const summaryApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.Summary],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getSummariesSections: build.query<Sections, string>({
        query: (encodedSubjectName) => ({
          url: `/summaries/topics/${encodedSubjectName}/sections`,
        }),
        providesTags: [ApiTagTypes.Summary],
      }),
      putReadStatus: build.mutation<
        void,
        {
          encodedSubjectName: string;
          encodedChapterName: string;
          encodedSectionName: string;
          body: { read: boolean };
        }
      >({
        query: (props) => ({
          url: `/read-status/subject/${props.encodedSubjectName}/chapters/${props.encodedChapterName}/sections/${props.encodedSectionName}`,
          method: 'PUT',
          body: props.body,
        }),
      }),
    }),
  });

export const { useGetSummariesSectionsQuery, usePutReadStatusMutation } =
  summaryApi;
