import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { appRoutes } from '../../../constants/routes';
import { Messages } from '../../../global.d';
import { useError } from '../../../hooks/useError';
import { UserRole } from '../../../models/UserRole';
import { Error, useLoginMutation } from '../../../services/userApi';
import { useAppDispatch } from '../../../store/hooks';
import { setToken } from '../../../store/slices/userSlice';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { InputFieldComponent } from '../../common/Input';
import { useDevice } from '../hooks/useDevice';
import { LoginCardBase } from '../LoginCardBase';
import { LoginSocial } from '../social/LoginSocial';

import { LoginCardHeader } from './LoginCardHeader';
import { LoginFooter } from './LoginFooter';
import { LoginSchema, FormData, FormFields } from './LoginFormSchema';

type Props = {
  onForgotClick: () => void;
};

export const LoginCard = ({ onForgotClick }: Props) => {
  const t = useTranslations();
  const [login, { isLoading }] = useLoginMutation();

  const dispatch = useAppDispatch();
  const device = useDevice();
  const navigate = useNavigate();
  const { showErrorModal } = useError();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(LoginSchema),
    reValidateMode: 'onChange',
  });

  const { ref: emailRef, ...emailRest } = register(FormFields.email, {
    required: true,
  });
  const { ref: passwordRef, ...passwordRest } = register(FormFields.password, {
    required: true,
  });

  const onSubmit = (data: FormData) => handleLogin(data.email, data.password);

  const handleLogin = async (email: string, password: string) => {
    try {
      const user = await login({
        admin: false,
        email,
        password,
        device: device,
      }).unwrap();

      dispatch(setToken(user.token));
      localStorage.setItem('token', JSON.stringify(user.token));

      if (user.accountType === UserRole.teacher) {
        window.location.href = appRoutes.TeacherDashboard;
      } else {
        navigate(appRoutes.Dashboard);
      }
    } catch (err) {
      const error = err as Error;
      switch (error.status) {
        case 400:
          setError(FormFields.email, {
            type: 'manual',
            message: t('no_valid_email'),
          });
          break;
        case 401:
          setError(FormFields.password, {
            type: 'manual',
            message: t('login_combo_not_known'),
          });
          break;
        case 406:
          setError(FormFields.email, {
            type: 'manual',
            message: t('login_too_many_attempts'),
          });
          break;
        case 409:
          setError(FormFields.email, {
            type: 'manual',
            message: t('too_many_devices'),
          });
          break;
        default:
          showErrorModal(error);
          break;
      }
    }
  };

  const getErrorText = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <LoginCardBase
      onSubmit={handleSubmit(onSubmit)}
      header={<LoginCardHeader />}
      footerButtons={
        <LoginFooter
          disabled={isLoading}
          onCreateAccountClick={() =>
            navigate(`${appRoutes.Login}?register=true`)
          }
        />
      }
    >
      <LoginSocial />
      <InputFieldComponent
        label={t('e-mail')}
        type="e-mail"
        aria-label="e-mail"
        hasError={errors.email !== undefined}
        errorText={getErrorText(errors.email)}
        placeholder={t('placeholder_e-mail')}
        {...emailRest}
        inputRef={emailRef}
      />
      <InputFieldComponent
        label={t('password')}
        type="password"
        aria-label="password"
        hasError={errors.password !== undefined}
        errorText={getErrorText(errors.password)}
        placeholder={t('placeholder_password')}
        {...passwordRest}
        inputRef={passwordRef}
      />
      <ForgetLink onClick={onForgotClick}>{t('forgot_password')}</ForgetLink>
    </LoginCardBase>
  );
};

const ForgetLink = styled.a`
  display: block;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ButtonTextSmallText}
`;
