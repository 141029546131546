import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';

import { Illustrations } from '../../constants/Illustrations';
import { HookMediaQueries } from '../../constants/MediaQueries';
import { useAppSelector } from '../../store/hooks';

import { BaseNavHeader } from './BaseNavHeader';
import { CardButton } from './CartButton';
import { MobileHeader } from './MobileHeader';
import { NavButtons } from './NavButtons';
import { ProfileButton } from './ProfileButton';

export const NavigationHeader = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const navigate = useNavigate();
  const cartItems = useAppSelector((state) => state.cart.items);

  return (
    <BaseNavHeader>
      {isDesktop ? (
        <>
          <LeftContainer>
            <Logo src={Illustrations.exoLogo} onClick={() => navigate('/')} />
            <NavButtons />
          </LeftContainer>
          <RightContainer>
            <ProfileButton />
            {!!cartItems.length && (
              <CardButton cartItemsAmount={cartItems.length} />
            )}
          </RightContainer>
        </>
      ) : (
        <MobileHeader />
      )}
    </BaseNavHeader>
  );
};
const Logo = styled.img`
  margin-right: 8px;
  height: 38px;
  cursor: pointer;
`;
const LeftContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RightContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
`;
