import React from 'react';

import { DashboardIcon } from '../components/Icons/DashboardIcon';
import { IconProps } from '../components/Icons/Icon';
import { ProfileIcon } from '../components/Icons/ProfileIcon';
import { ScheduleIcon } from '../components/Icons/ScheduleIcon';
import { SubjectsIcon } from '../components/Icons/SubjectsIcon';
import { Messages } from '../global.d';

export type Route = {
  path: string;
  titleKey: keyof Messages;
};

export type RouteWithLogo = {
  name: keyof Messages;
  logo: React.ReactElement<IconProps>;
  to: string;
};

export const enum AppRoutePathNames {
  Dashboard = '',
  Cart = 'cart',
  Subject = 'subject',
  Subjects = 'subjects',
  Login = 'login',
  Reset = 'reset',
  Unblock = 'unblock',
  Profile = 'profile',
  Schedule = 'schedule',
  Summary = 'summary',
  Practice = 'practice',
  Result = 'result',
  Home = 'home',
  Definitions = 'definitions',
  Tips = 'tips',
  Exercises = 'exercises',
  Videos = 'videos',
  Disclaimer = 'disclaimer',
  FAQ = 'faq',
  Products = 'products',
  Devices = 'devices',
  Onboarding = 'onboarding',
  EmailVerification = 'email-verification',
  LinkProduct = 'link-product',
  StudentType = 'student-type',
  RelevantSection = 'most-relevant',
  OldExams = 'old-exams',
  ChangeSubjects = 'change-subjects',
  ChangeEducationLevel = 'change-education-level',
  PaymentStatus = 'payment/status',
  Badges = 'badges',
}

export const appRoutes = {
  Login: `/${AppRoutePathNames.Login}`,
  Dashboard: '/',
  Subject: `/${AppRoutePathNames.Subject}`,
  Subjects: `/${AppRoutePathNames.Subjects}`,
  Schedule: `/${AppRoutePathNames.Schedule}`,
  Profile: `/${AppRoutePathNames.Profile}`,
  Badges: `/${AppRoutePathNames.Profile}/${AppRoutePathNames.Badges}`,
  ProfileChangeSubjects: `/${AppRoutePathNames.Profile}/${AppRoutePathNames.ChangeSubjects}`,
  ProfileChangeEducationLevel: `/${AppRoutePathNames.Profile}/${AppRoutePathNames.ChangeEducationLevel}`,
  Onboarding: `/${AppRoutePathNames.Onboarding}`,
  OnboardingEmailVerification: `/${AppRoutePathNames.Onboarding}/${AppRoutePathNames.EmailVerification}`,
  OnboardingLinkProduct: `/${AppRoutePathNames.Onboarding}/${AppRoutePathNames.LinkProduct}`,
  OnboardingStudentType: `/${AppRoutePathNames.Onboarding}/${AppRoutePathNames.StudentType}`,
  OnboardingRelevantSection: `/${AppRoutePathNames.Onboarding}/${AppRoutePathNames.RelevantSection}`,
  OnboardingSubjects: `/${AppRoutePathNames.Onboarding}/${AppRoutePathNames.Subjects}`,
  PaymentStatus: `/${AppRoutePathNames.PaymentStatus}`,
  TeacherDashboard: '/leraren-dashboard',
};

export const mainRoutes: Route[] = [
  { path: appRoutes.Dashboard, titleKey: 'dashboard' },
  { path: appRoutes.Subject, titleKey: 'subjects' },
  { path: appRoutes.Schedule, titleKey: 'schedule' },
];

export const mainRoutesWithLogo: RouteWithLogo[] = [
  {
    name: 'dashboard',
    logo: <DashboardIcon />,
    to: appRoutes.Dashboard,
  },
  {
    name: 'subjects',
    logo: <SubjectsIcon />,
    to: appRoutes.Subjects,
  },
  {
    name: 'schedule',
    logo: <ScheduleIcon />,
    to: appRoutes.Schedule,
  },
  { name: 'profile', logo: <ProfileIcon />, to: appRoutes.Profile },
];

export const navigationHeaderLocationMap: Record<string, keyof Messages> = {
  '/': 'dashboard',
  '/subject': 'subjects',
  '/schedule': 'schedule',
  '/profile/home': 'profile',
};

export const subjectRoutes: Route[] = [
  { path: AppRoutePathNames.Home, titleKey: 'subject_menu_home' },
  { path: AppRoutePathNames.Definitions, titleKey: 'subject_menu_definitions' },
  { path: AppRoutePathNames.Exercises, titleKey: 'subject_menu_exercises' },
  { path: AppRoutePathNames.Tips, titleKey: 'subject_menu_tips' },
  { path: AppRoutePathNames.Videos, titleKey: 'subject_menu_videos' },
  { path: AppRoutePathNames.OldExams, titleKey: 'subject_menu_old_exams' },
];

export const profileRoutes: Route[] = [
  { path: AppRoutePathNames.Home, titleKey: 'profile_menu_home' },
  { path: AppRoutePathNames.Devices, titleKey: 'profile_menu_devices' },
  { path: AppRoutePathNames.Products, titleKey: 'profile_menu_products' },
  { path: AppRoutePathNames.FAQ, titleKey: 'profile_menu_faq' },
  { path: AppRoutePathNames.Disclaimer, titleKey: 'profile_menu_disclaimer' },
];

export const makeSubjectHomeRoute = (encodedName: string) =>
  `/${AppRoutePathNames.Subject}/${encodedName}/${AppRoutePathNames.Home}`;

export const makeSubjectSummaryRoute = (encodedSubjectName: string) => {
  return `/${AppRoutePathNames.Subject}/${encodedSubjectName}/${AppRoutePathNames.Summary}`;
};

export const makeSubjectDefinitionsRoute = (encodedSubjectName: string) => {
  return `/${AppRoutePathNames.Subject}/${encodedSubjectName}/${AppRoutePathNames.Definitions}`;
};

export const makeSubjectOldExamsRoute = (
  encodedSubjectName: string,
  examYear?: string,
  examPeriod?: string,
  documentKey?: string
) => {
  return `/${AppRoutePathNames.Subject}/${encodedSubjectName}/${AppRoutePathNames.OldExams}${examYear ? `/${examYear}` : ''}${examPeriod ? `/${examPeriod}` : ''}${documentKey ? `/${documentKey}` : ''}`;
};

export const getSubjectEncodedNameFromLocation = () => {
  const { pathname } = window.location;

  // match subject path
  const regEx = new RegExp(`^/${AppRoutePathNames.Subject}/*`, 'g');

  if (pathname.match(regEx)) {
    return pathname.split('/').filter((r) => r)[1];
  }
};
