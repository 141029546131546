import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { useSubjectContext } from '../../../../context/SubjectContext';
import { Section } from '../../../../models/Summary/summary';
import { PrimaryButton } from '../../../common/Button';
import { useSectionActions } from '../useSectionActions';

export const SectionActions = ({
  chapterSection,
  encodedChapterName,
  videosActive,
}: {
  chapterSection: Section;
  encodedChapterName: string;
  videosActive: boolean;
}) => {
  const t = useTranslations();
  const { subjectColor } = useSubjectContext();

  const { openSummary, openVideo, openTraining } = useSectionActions();

  return (
    <Container>
      <PrimaryButton
        buttonSize="sm"
        textSize="xs"
        buttonColor={subjectColor}
        onClick={() => openSummary(chapterSection)}
        style={{ border: 'none' }}
      >
        {t('common_summary')}
      </PrimaryButton>
      {videosActive && (
        <PrimaryButton
          buttonSize="sm"
          textSize="xs"
          buttonColor={subjectColor}
          onClick={() => openVideo(chapterSection)}
          style={{ border: 'none' }}
        >
          {t('common_video')}
        </PrimaryButton>
      )}
      {chapterSection.hasPracticeModule && (
        <PrimaryButton
          buttonSize="sm"
          textSize="xs"
          buttonColor={subjectColor}
          onClick={() =>
            openTraining({
              section: chapterSection,
              encodedChapterName,
              startNewTraining: true,
            })
          }
          style={{ border: 'none' }}
        >
          {t('common_challenge')}
        </PrimaryButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
