import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';

import { ProductBundle } from '../../models/ProductBundle';
import { useAppDispatch } from '../../store/hooks';
import { deleteFromCart } from '../../store/slices/cartSlice';
import { RootState } from '../../store/store';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { setCoverUrlToSummary } from '../../utils/apiUtils';
import { CardType, CartItem } from '../CartItem';
import { CardBase } from '../common/CardBase';

export const CartItemCard = () => {
  const t = useTranslations();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useAppDispatch();

  const onRemoveItem = (product: ProductBundle) => {
    dispatch(deleteFromCart(product));
  };

  
  return (
    <CardBase header={<Title>{t('your_order')}</Title>} fullWidth={true}>
      {cartItems.map((product: ProductBundle) => {
        return (
          <CartItem
            key={product._id}
            image={setCoverUrlToSummary(product.cover)}
            title={product.description}
            subtitle={`${product.name} ${product.courseLevels?.[0] || ''}`}
            price={`€${product.price}`}
            onPress={() => onRemoveItem(product)}
            cardType={CardType.removeFromCart}
          />
        );
      })}
    </CardBase>
  );
};

const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
