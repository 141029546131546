import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../../constants/routes';
import { useError } from '../../../hooks/useError';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { Token } from '../../../models/User';
import { UserRole } from '../../../models/UserRole';
import { useRegisterMutation } from '../../../services/onboardingApi';
import { useAppDispatch } from '../../../store/hooks';
import { clearUserManagementState } from '../../../store/slices/userManagementSlice';
import { setNewUser, setToken } from '../../../store/slices/userSlice';

export const useRegister = () => {
  const [registerTrigger] = useRegisterMutation();
  const { showErrorModal } = useError();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userManagementState = useUserManagement();

  const register = async () => {
    try {
      await registerTrigger(userManagementState).unwrap();

      // disable navigating back to the onboarding flow after registration
      window.history.pushState({}, '', appRoutes.Login);
      window.location.href = appRoutes.OnboardingEmailVerification;
    } catch (error) {
      showErrorModal(error as Error);
    }
  };

  const successfulVerification = (token: Token) => {
    dispatch(setNewUser());
    dispatch(setToken(token));
    localStorage.setItem('token', JSON.stringify(token));

    if (userManagementState.accountType === UserRole.teacher) {
      window.location.href = appRoutes.TeacherDashboard;
    } else {
      navigate(appRoutes.OnboardingLinkProduct);
    }
    dispatch(clearUserManagementState());
  };

  return { register, successfulVerification };
};
