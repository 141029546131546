import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { MediaQueries } from '../../constants/MediaQueries';
import { UserBadge } from '../../models/Badge';
import { useGetBadgesQuery } from '../../services/userApi';
import { BadgeComponent } from './BadgeComponent';

export const Badges = () => {
  const t = useTranslations();
  const { data: badges } = useGetBadgesQuery();

  const filteredBadges = badges?.reduce((acc, curr)=>{
      const alreadyIncludedBadge = acc.find(item=>item.badge.name === curr.badge.name)
      if(alreadyIncludedBadge){
        // this condition assumes the different badge tiers come ordered by ascending importance from the backend
        if(curr.isUnlocked || alreadyIncludedBadge.isUnlocked){
            return [...acc.filter(item=>item.badge.name !== alreadyIncludedBadge.badge.name), curr]
        }
    
        return acc
    }
    return [...acc, curr]
  },[] as UserBadge[])

  return (
    <Container>
      {filteredBadges?.map((badge) => (<BadgeComponent key={badge._id} badge={badge} />))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0px;
  @media (max-width: ${MediaQueries.desktop}) {
    padding-top: 62px;
  }
`;

