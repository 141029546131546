import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentStatus } from '../../models/payments';
import { ProductBundle } from '../../models/ProductBundle';
import { Slices } from '../Slices';

export const SHIPPING_COST = 3;
export const FREE_SHIPPING_REQUIREMENT_PRICE = 30;

export type Price = {
  shippingCost: number;
  subTotal: number;
  grandTotal: number;
};

type CartState = {
  items: ProductBundle[];
  discount?: Discount;
  price: Price;
  paymentStatus?: string;
  paymentId?: string;
};

const initialState: CartState = {
  items: [],
  price: {
    shippingCost: 0,
    subTotal: 0,
    grandTotal: 0,
  },
  paymentStatus: '',
  paymentId: '',
};

export type Discount = {
  code: string;
  amount: number;
  description: string;
};

const calculateTotal = (cart: CartState) => {
  let subTotal = cart.items.reduce((accumulator, { price }) => {
    return accumulator + price;
  }, 0);

  if (cart.discount) {
    subTotal -= Math.abs(cart.discount.amount);
  }
  const shippingCost =
    subTotal > FREE_SHIPPING_REQUIREMENT_PRICE ? 0 : SHIPPING_COST;
  const grandTotal = subTotal + shippingCost;
  return {
    subTotal,
    shippingCost,
    grandTotal,
  };
};

const cartSlice = createSlice({
  name: Slices.Cart,
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<ProductBundle>) => {
      const product = action.payload;
      const cartProductFound = state.items.find(
        (item) => item._id === product._id
      );
      if (!cartProductFound) {
        state.items.push(product);
        state.price = calculateTotal(state);
      }
    },
    addDiscount: (state, action: PayloadAction<Discount>) => {
      state.discount = action.payload;
      state.price = calculateTotal(state);
    },
    deleteFromCart: (state, action: PayloadAction<ProductBundle>) => {
      const productId = action.payload._id;
      state.items = state.items.filter((item) => item._id !== productId);
      state.price = calculateTotal(state);
    },
    setPaymentStatus: (state, action: PayloadAction<PaymentStatus>) => {
      state.paymentStatus = action.payload;
    },
    setPaymentId: (state, action: PayloadAction<string>) => {
      state.paymentId = action.payload;
    },
    clearCart: (state) => {
      state.items = [];
      state.price = calculateTotal(state);
      state.paymentStatus = undefined;
      state.paymentId = undefined;
    },
  },
});

export const {
  addToCart,
  addDiscount,
  deleteFromCart,
  setPaymentStatus,
  setPaymentId,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
