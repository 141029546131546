import styled from '@emotion/styled';
import DOMPurify from 'dompurify';
import { useTranslations } from 'use-intl';

import { Question } from '../../../../models/training/Question';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { PracticeCMS } from '../PracticeCMS';

type Props = {
  question: Question;
};

export const CorrectAnswerBlock = ({ question }: Props) => {
  const t = useTranslations();
  return (
    <>
      <Title>{t('the_correct_answer_is')}</Title>
      <CorrectAnswer>
        <PracticeCMS>
          <Introduction
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(question.explanation || ''),
            }}
          />
        </PracticeCMS>
      </CorrectAnswer>
    </>
  );
};
const Title = styled.h1`
  color: ${Colors.inputTextColor};
  ${TextStyles.InputHintText}
`;
const Introduction = styled.div``;
const CorrectAnswer = styled.div`
  border-radius: 4px;
  padding: 12px 16px;
  background-color: ${Colors.backgroundColor};
`;
