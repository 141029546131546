import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../constants/routes';
import { UserSubject, SubjectCategory, Subject } from '../../models/Subject';
import { UserRole } from '../../models/UserRole';
import { useGetUserQuery } from '../../services/userApi';
import { useAppDispatch } from '../../store/hooks';
import {
  clearUserManagementState,
  setCourseLevel,
  setSubjects,
  setTeacherCourseLevels,
} from '../../store/slices/userManagementSlice';
import { CardBase } from '../common/CardBase';

import { ProfileCardHeader } from './ProfileCardHeader';
import { SubjectRow } from './subjects/SubjectRow';

type Props = {
  subjects?: UserSubject[];
  allSubjects: Subject[];
};

export const UserSubjectsCard = ({ subjects, allSubjects }: Props) => {
  const { data: user } = useGetUserQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onEditSubjects = () => {
    if (!user) return;
    dispatch(clearUserManagementState());
    dispatch(setSubjects(allSubjects));
    if (user.accountType === UserRole.teacher) {
      dispatch(setTeacherCourseLevels(user.teacherCourseLevels));
    } else {
      dispatch(setCourseLevel(user.courseLevel));
    }

    navigate(appRoutes.ProfileChangeSubjects);
  };

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <ProfileCardHeader
          titleTranslationKey="my_subjects"
          buttonTranslationKey="edit_my_subjects"
          onButtonClick={onEditSubjects}
        />
      }
    >
      {subjects?.map((subject) => {
        if (subject.subject.category !== SubjectCategory.Extra) {
          return <SubjectRow key={subject.subject._id} userSubject={subject} />;
        }
      })}
    </CardBase>
  );
};
