import { Exercise } from './Exercise';

export type ProductBundle = {
  _id: string;
  name: string;
  price: number;
  discountPercentage: number;
  products: string[];
  courseLevels: string[];
  subjects: string[];
  description: string;
  cover: string;
  bundleProducts?: Exercise[];
};

export const productTypePrefix = {
  summary: 'samenvatting',
  exercises: 'oefenboek',
  practiceModule: 'oefenmodule',
  videos: 'uitlegvideos',
};

export enum Role {
  admin = 'admin',
  user = 'user',
  teacher = 'teacher',
}

export type School = {
  _id: string;
  name: string;
};
