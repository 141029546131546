import styled from '@emotion/styled';
import { ReactElement } from 'react';

import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { CardBase, RenderStyle } from './common/CardBase';

type VideoActionSubjectCardProps = {
  subjectColor?: string;
  onPress: () => void;
  tagTitle: string;
  backgroundImage?: string;
  iconVariation?: ReactElement;
  highlightedText: string;
  regularText: string;
};

export const VideoActionSubjectCard = ({
  subjectColor,
  onPress,
  tagTitle,
  backgroundImage,
  iconVariation,
  highlightedText,
  regularText,
}: VideoActionSubjectCardProps) => {
  return (
    <Button
      onClick={onPress}
      bgColor={subjectColor}
      renderStyle={RenderStyle.desktop}
    >
      {backgroundImage && (
        <BackgroundImage
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        />
      )}
      <Container>
        <ExoTag bgColor={Colors.tagColor}>
          {iconVariation} {tagTitle}
        </ExoTag>

        <ExplanationVideoText textColor={Colors.tagColor}>
          {highlightedText}
        </ExplanationVideoText>
        <ExplanationVideoText>{regularText}</ExplanationVideoText>
      </Container>
    </Button>
  );
};

const Button = styled(CardBase)<{ bgColor?: string }>`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const ExoTag = styled.div<{ bgColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  background-color: ${(props) => props.bgColor || Colors.tagColor};
  padding: 5px;
  border-radius: 4px;
  position: relative;
  background-color: ${(props) => props.bgColor || Colors.tagColor};
  color: ${Colors.primaryButtonContentColor};
  ${TextStyles.DiscountLabelText}
`;

const ExplanationVideoText = styled.span<{ textColor?: string }>`
  color: ${(props) => props.textColor ?? Colors.primary};
  font-weight: ${(props) => props.textColor && 700};
  ${TextStyles.ListItemBody}
  position: relative;
`;

const BackgroundImage = styled.div`
  position: absolute;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(4px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
