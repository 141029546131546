import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { SubjectImage } from '../common/SubjectImage';

type Props = {
  active: boolean;
  imageUrl: string;
  subjectName: string;
  subjectEncodedName: string;
  grade?: number;
};

export const SubjectScrollItem = ({
  active,
  imageUrl,
  subjectName,
  subjectEncodedName,
  grade,
}: Props) => {
  const t = useTranslations();
  const navigate = useNavigate();

  const navigateToSubject = () => {
    navigate(`/subject/${subjectEncodedName}/home`);
  };

  return (
    <Container disabled={!active} onClick={navigateToSubject}>
      <SubjectImage src={imageUrl} />
      <Name>{subjectName}</Name>
      <Start>{grade ? grade : t('start')}</Start>
    </Container>
  );
};
const Container = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
  display: flex;
  flex-direction: column;
  flex-basis: 140px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 120px;
  align-items: center;
  text-align: center;
  padding: 16px 10px;
  cursor: pointer;
`;
const Name = styled.h1`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
  display: block;
  width: 100%;
  color: ${Colors.subjectTextColor};
  ${TextStyles.ListItemTitle}
`;
const Start = styled.p`
  margin: 0;
  color: ${Colors.subjectTextColor};
  ${TextStyles.ListItemSubtitle}
`;
