import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { Colors } from '../../../styles/colors';

type Props = {
  shouldAlignCenter: boolean;
} & PropsWithChildren;

const PADDING = 72;

export const PracticeContainerRight = ({
  children,
  shouldAlignCenter,
}: Props) => (
  <Container shouldAlignCenter={shouldAlignCenter}>{children}</Container>
);

const Container = styled.div<{ shouldAlignCenter: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ shouldAlignCenter }) =>
    shouldAlignCenter ? 'center' : 'flex-start'};
  padding: ${PADDING}px 12.5%;
  width: 75%;
  background-color: ${Colors.modalBackgroundColor};

  @media ${HookMediaQueries.isDesktop} {
    height: calc(100vh - ${PADDING * 2}px - ${HEADER_HEIGHT_PX}px);
    overflow-y: auto;
  }
`;
