import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { CheckIcon } from '../../../Icons/CheckIcon';
import { CloseIcon } from '../../../Icons/CloseIcon';

type Props = {
  correct: boolean;
};

export const AnswerValidText = ({ correct }: Props) => {
  const t = useTranslations();
  const color = correct ? Colors.topicPositive : Colors.topicNegative;

  return (
    <Container color={color}>
      {correct ? t('answer_correct') : t('answer_wrong')}
      {correct ? <CheckIcon color={color} /> : <CloseIcon color={color} />}
    </Container>
  );
};
const Container = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: ${({ color }) => color};
  ${TextStyles.InputHintText}
`;
