import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import {
  SUMMARY_HEADER_HEIGHT,
  SUMMARY_SIDE_MENU_WIDTH,
} from '../../../constants/Sizes';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CloseIcon } from '../../Icons/CloseIcon';

import { CurrentSummarySegment } from './hooks/useSummaryData';
import { TestSectionButton } from './TestSectionButton';

type SummaryHeaderType = {
  current: CurrentSummarySegment;
  subjectColor: string;
  isNavActive: boolean;
  isPreview: boolean;
  isPracticeModuleActive: boolean;
};

export const SummaryHeader = ({
  current,
  subjectColor,
  isNavActive,
  isPreview,
  isPracticeModuleActive,
}: SummaryHeaderType) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const { encodedSubjectName } = useParams();
  const navigate = useNavigate();

  return (
    <SummaryContainer>
      <ContentContainer>
        <TextContainer isNavActive={isNavActive}>
          <SummaryTitle>{current.chapter.chapterTitle}</SummaryTitle>
          <ChapterTitle>{current.section.sectionTitle}</ChapterTitle>
        </TextContainer>
        {isDesktop && isPracticeModuleActive && (
          <ButtonContainer>
            <TestSectionButton
              buttonColor={subjectColor}
              encodedChapterName={current.chapter.encodedChapterName}
              encodedSectionName={current.section.encodedSectionName}
              encodedSubjectName={encodedSubjectName ?? ''}
              isPreview={isPreview}
            />
          </ButtonContainer>
        )}
      </ContentContainer>
      <IconContainer>
        <CloseIcon
          onClick={() => navigate('../home', { relative: 'path' })}
          color="black"
        />
      </IconContainer>
    </SummaryContainer>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid ${Colors.dividerColor};
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  background-color: ${Colors.summaryBackgroundColor};
  height: calc(${SUMMARY_HEADER_HEIGHT} + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
`;
const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px 0 20px;
`;

const SummaryTitle = styled.h1`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const ChapterTitle = styled.h1`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardSubtitleText}
`;

const TextContainer = styled.div<{ isNavActive: boolean }>`
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
  transition: padding-left 0.5s ease;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden;

  @media ${HookMediaQueries.isDesktop} {
    padding-left: ${(props) => props.isNavActive && SUMMARY_SIDE_MENU_WIDTH};
    transition: padding-left 0.5s ease;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  padding-left: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
