import { Section, Sections } from '../../../../models/Summary/summary';

export enum SummaryMode {
  Summary = 'summary',
  Video = 'video',
  Both = 'both',
}

export const enum BuyableType {
  Summary = 'summary',
  Training = 'training',
  Videos = 'videos',
}

export const enum ProductTypePrefix {
  Summary = 'samenvatting',
  Exercises = 'oefenboek',
  PracticeModule = 'oefenmodule',
  Videos = 'uitlegvideos',
}

export const hasActivePracticeModule = (
  selectedSection: Section,
  summarySections: Sections
) => {
  return (
    selectedSection?.hasPracticeModule && summarySections?.practiceModuleActive
  );
};

export const getProductTypePrefixFromBuyableType = (
  buyableType: BuyableType
) => {
  switch (buyableType) {
    case BuyableType.Training:
      return ProductTypePrefix.PracticeModule;
    case BuyableType.Summary:
      return ProductTypePrefix.Summary;
    case BuyableType.Videos:
      return ProductTypePrefix.Videos;
  }
};

export const getBuyableFilterBySummaryMode = (summaryMode: SummaryMode) => {
  switch (summaryMode) {
    case SummaryMode.Summary:
      return BuyableType.Summary;
    case SummaryMode.Video:
      return BuyableType.Videos;
    default:
      return BuyableType.Summary;
  }
};
