import styled from '@emotion/styled';
import DOMPurify from 'dompurify';
import { useState } from 'react';

import { Question } from '../../../../models/training/Question';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { SCORE_SET_BY_BACKEND } from '../hooks/useAnswerQuestion';
import { PracticeCMS } from '../PracticeCMS';

import { AnswerValidText } from './AnswerValidText';
import { CorrectAnswerBlock } from './CorrectAnswerBlock';
import { MultipleChoiceOption } from './MultipleChoiceOption';

type Props = {
  question: Question;
  onAnswerQuestion: (
    answer: string,
    questionId: string,
    numberOfPoints: number
  ) => void;
};

export const MultipleChoiceQuestion = ({
  question,
  onAnswerQuestion,
}: Props) => {
  const [choice, setChoice] = useState('');

  const onChoiceClick = (newChoice: string) => {
    if (choice !== '') return;
    setChoice(newChoice);
    onAnswerQuestion(newChoice, question._id, SCORE_SET_BY_BACKEND);
  };

  const getOptionColor = (choiceOption: string) => {
    if (!choice) return '';

    if (question.correctAnswer == choiceOption) {
      return Colors.topicPositive;
    }
    if (choiceOption == choice) {
      return Colors.topicNegative;
    }
    return '';
  };

  return (
    <Container>
      <Title>{question.title}</Title>
      <Introduction
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(question.introduction),
        }}
      />
      <Options>
        {question.choices.map((choice, i) => (
          <MultipleChoiceOption
            key={i}
            title={choice}
            color={getOptionColor(choice)}
            onClick={() => onChoiceClick(choice)}
            position={i}
          />
        ))}
      </Options>
      {choice && (
        <>
          <AnswerValidText correct={question.correctAnswer == choice} />
          {question.correctAnswer !== choice && (
            <CorrectAnswerBlock question={question} />
          )}
        </>
      )}
    </Container>
  );
};
const Container = styled.div`
  padding: 8px 0;
`;
const Title = styled.h1`
  color: ${Colors.inputTextColor};
  ${TextStyles.InputHintText}
`;
const Introduction = styled.div``;
const Options = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
