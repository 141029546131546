import { LockedSubjectChapter } from '../components/LockedSubjectChapter';
import { useSubjectContext } from '../context/SubjectContext';
import { Section } from '../models/Summary/summary';

import { UnlockedSubjectChapter } from './UnlockedSubjectChapter';

export const SubjectTile = ({
  chapterSections,
  encodedChapterName,
}: {
  chapterSections: Section[];
  encodedChapterName: string;
}) => {
  const { sections } = useSubjectContext();
  const { previewSummary, previewExercise } = sections || {};
  const isChapterLocked = (section: Section) =>
    previewSummary && previewExercise && !section.preview;

  return (
    <>
      {chapterSections.map((chapterSection, i) => (
        <div key={i}>
          {isChapterLocked(chapterSection) ? (
            <LockedSubjectChapter title={chapterSection.sectionTitle} />
          ) : (
            <UnlockedSubjectChapter
              chapterSection={chapterSection}
              encodedChapterName={encodedChapterName}
              videosActive={!!sections?.videosActive}
            />
          )}
        </div>
      ))}
    </>
  );
};
