import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useEffect, useRef } from 'react';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { Subject } from '../../../models/Subject';
import { Sections, Video } from '../../../models/Summary/summary';

import { CurrentSummarySegment } from './hooks/useSummaryData';
import { SummaryBuyables } from './SummaryBuyables';
import { SummaryReader } from './SummaryReader';
import {
  getBuyableFilterBySummaryMode,
  SummaryMode,
} from './utils/summaryUtils';
import { VideoRecommendations } from './video/VideoRecommendations';
import { Videos } from './video/Videos';
import { Watermark } from './Watermark';

type Props = {
  summary: Sections;
  subject?: Subject;
  summaryMode: SummaryMode;
  activeVideo: Video | undefined;
  setActiveVideo: (video: Video | undefined) => void;
  current: CurrentSummarySegment;
  hasNextSection: boolean;
  isPracticeModeActive: boolean;
  onSelectNextTopicClick: () => void;
};

export const SummaryContent = ({
  summary,
  subject,
  summaryMode,
  activeVideo,
  setActiveVideo,
  current,
  hasNextSection,
  onSelectNextTopicClick,
  isPracticeModeActive,
}: Props) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    scrollableRef?.current?.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [current]);

  const showTopic =
    summaryMode === SummaryMode.Summary || summaryMode === SummaryMode.Both;
  const showVideo =
    summaryMode === SummaryMode.Video || summaryMode === SummaryMode.Both;
  const showVideoRecommendations =
    current.videos.length > 1 &&
    (summaryMode === SummaryMode.Video ||
      (!isDesktop && summaryMode === SummaryMode.Both));

  return (
    <Container>
      {subject && (
        <>
          {showVideo && (
            <Videos
              videoList={current.videos}
              subjectColor={subject.color}
              summaryMode={summaryMode}
              activeVideo={activeVideo}
              onClickVideoCard={(video) => setActiveVideo(video)}
            />
          )}
          <ScrollContainer ref={scrollableRef}>
            {showVideoRecommendations && (
              <StyledVideoRecommendations
                videoList={current.videos}
                activeVideo={activeVideo}
                onClickVideoCard={(video) => setActiveVideo(video)}
                subjectColor={subject.color}
                summaryMode={summaryMode}
              />
            )}
            <SummaryBuyables
              buyableTypeFilter={getBuyableFilterBySummaryMode(summaryMode)}
              subjectId={subject._id}
            />
            {showTopic && (
              <SummaryReader
                summary={summary}
                chapter={current.chapter}
                section={current.section}
                color={subject.color}
                hasNextSection={hasNextSection}
                onSelectNextTopicClick={onSelectNextTopicClick}
                isPracticeModeActive={isPracticeModeActive}
              />
            )}
          </ScrollContainer>
          <Watermark color={subject.color} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const ScrollContainer = styled.div`
  padding: 0 20px;
  overflow-y: scroll;

  @media ${HookMediaQueries.isDesktop} {
    padding: 0 56px;
  }
`;
const StyledVideoRecommendations = styled(VideoRecommendations)`
  max-width: 800px;
  margin: auto;
`;
