import styled from '@emotion/styled';
import { useMemo } from 'react';

import { VideoJS } from './VideoJS';

type Props = {
  videoUrl: string;
};

export const VideoPlayer = ({ videoUrl }: Props) => {
  const videoJsOptions = useMemo(() => {
    return {
      autoplay: true,
      controls: true,
      responsive: true,
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
      fluid: true,
      sources: [
        {
          src: videoUrl,
          type: 'application/x-mpegURL',
        },
      ],
    };
  }, [videoUrl]);

  return (
    <Player>
      <VideoJS options={videoJsOptions} />
    </Player>
  );
};
const Player = styled.div``;
