import { Capacitor } from '@capacitor/core';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import LockedBadgeImage from '../../assets/images/badge-locked.png';
import { appRoutes } from '../../constants/routes';
import { BadgeProgress, UserBadge } from '../../models/Badge';
import { useGetFeaturedBadgesQuery } from '../../services/dashboardApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { SecondaryButton } from '../common/Button';
import { CardBase } from '../common/CardBase';
import { ProgressBar } from '../ProgressBar';

export const getBadgeProgress = (badge: UserBadge | undefined) => {
  const progress = badge?.progress.reduce((acc, curr) => {
    if (isEmpty(acc)) return curr;
    if (acc.amount !== acc.badgeGoal.targetAmount) {
      return acc;
    }
    return {
      ...acc,
      amount: acc.amount + curr.amount,
      badgeGoal: {
        ...curr.badgeGoal,
        targetAmount: acc.badgeGoal.targetAmount + curr.badgeGoal.targetAmount,
      },
    };
  }, {} as BadgeProgress);
  return progress;
};

export const BadgesCard = () => {
  const t = useTranslations();
  const { data: badge } = useGetFeaturedBadgesQuery();
  const navigate = useNavigate();

  const getPercentage = (progress: BadgeProgress) =>
    (progress.amount / progress.badgeGoal.targetAmount) * 100;

  const progress = getBadgeProgress(badge);

  return (
    <CardBase fullWidth={true} fullWidthBody={true}>
      <Container>
        <BadgeIcon>
          {badge && (
            <Icon
              platform={Capacitor.getPlatform()}
              src={badge.isUnlocked ? badge.badge.icon : LockedBadgeImage}
            />
          )}
        </BadgeIcon>
        <BadgeProgressContainer>
          <BadgeTitle>{badge?.badge.name}</BadgeTitle>
          {!isEmpty(progress) && (
            <ProgressContainer key={progress.badgeGoal.description}>
              <ProgressBar
                progress={getPercentage(progress)}
                full={progress.badgeGoal.targetAmount === progress.amount}
              />
              <ProgressAmount>
                {progress.amount}/{progress.badgeGoal.targetAmount}
              </ProgressAmount>
            </ProgressContainer>
          )}

          <SecondaryButton
            buttonSize="sm"
            onClick={() => navigate(appRoutes.Badges)}
          >
            {t('show_all_badges')}
          </SecondaryButton>
        </BadgeProgressContainer>
      </Container>
    </CardBase>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const BadgeProgressContainer = styled.div`
  padding: 20px;
  flex-grow: 1;
`;
const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
`;
const BadgeIcon = styled.div`
  padding: 0 20px 20px 20px;
`;
const Icon = styled.img<{ platform: string }>`
  height: ${({ platform }) => (platform === 'ios' ? '100%' : 'fill-available')};
`;
const BadgeTitle = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const ProgressAmount = styled.p`
  padding-left: 16px;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ProgressAmountIndicator}
`;
