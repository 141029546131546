import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import {
  FREE_SHIPPING_REQUIREMENT_PRICE,
  SHIPPING_COST,
  Price,
} from '../store/slices/cartSlice';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

export const DiscountDetails = ({ price }: { price: Price }) => {
  const t = useTranslations();

  const postageCost =
    price.subTotal < FREE_SHIPPING_REQUIREMENT_PRICE
      ? `+ €${SHIPPING_COST},00`
      : t('free');
  return (
    <Container>
      <DetailsContainer>
        <Postage>{t('postage')}</Postage>
        <TotalPrice>{t('totaal')}</TotalPrice>
        <Postage>{`${t('free_postage')} ${FREE_SHIPPING_REQUIREMENT_PRICE}`}</Postage>
      </DetailsContainer>
      <DetailsContainer>
        <Postage>{postageCost}</Postage>
        <TotalPrice>{`€${price.grandTotal.toFixed(2)}`}</TotalPrice>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Postage = styled.p`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
const TotalPrice = styled.p`
  padding-top: 8px;
  padding-bottom: 4px;
  ${TextStyles.PriceBigText}
`;

const DetailsContainer = styled.div``;
