import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { useSubjectColorContext } from '../../../../context/SubjectColorContext';
import { useError } from '../../../../hooks/useError';
import { usePostRatingMutation } from '../../../../services/trainingApi';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { SubjectColorButton } from '../../../common/Button';
import { StarRating } from '../../../Inputs/StarRating';
import { TextAreaComponent } from '../../../Inputs/Textarea';

type Props = {
  thesisId: string;
  onNextQuestionClick: () => void;
};

const RATING_TEXT_AREA_MAX_LENGTH = 300;

export const RateQuestion = ({ thesisId, onNextQuestionClick }: Props) => {
  const t = useTranslations();
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [postRating] = usePostRatingMutation();
  const { subjectColor } = useSubjectColorContext();
  const { showErrorModal } = useError();

  const onRatingChange = (selected: number) => {
    setRating(selected + 1);
  };

  const rateThesis = async () => {
    try {
      postRating({
        thesisId: thesisId,
        body: { rating: rating || 0, message: message },
      });
    } catch (error) {
      showErrorModal(error);
    }
  };

  const onContinueClick = () => {
    if (rating !== undefined) {
      rateThesis();
    }

    onNextQuestionClick();
  };

  const showFeedbackText = rating !== undefined && rating <= 3;

  return (
    <Container>
      <Title>{t('rate_this_question')}</Title>
      <StarRating
        amountOfItems={5}
        color={subjectColor}
        onChange={onRatingChange}
      />
      {showFeedbackText && (
        <>
          <Title>{t('what_can_we_improve')}</Title>
          <TextAreaComponent
            rows={2}
            maxLength={RATING_TEXT_AREA_MAX_LENGTH}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </>
      )}
      <StyledSubjectColorButton
        fullWidth={true}
        buttonSize="md"
        buttonColor={subjectColor}
        onClick={onContinueClick}
      >
        {t('next_question')}
      </StyledSubjectColorButton>
    </Container>
  );
};
const Container = styled.div`
  padding: 8px 0;
`;
const Title = styled.h1`
  color: ${Colors.inputTextColor};
  ${TextStyles.InputHintText}
`;
const StyledSubjectColorButton = styled(SubjectColorButton)`
  margin-top: 16px;
`;
