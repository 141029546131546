import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import {
  SUMMARY_HEADER_HEIGHT,
  SUMMARY_SIDE_MENU_WIDTH,
} from '../../../constants/Sizes';
import { ZIndex } from '../../../constants/Zindex';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { Chapter, Section } from '../../../models/Summary/summary';
import {
  useGetSummaryByEncodedNameQuery,
  useGetSummarySectionsQuery,
} from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';
import { MultiSwitch } from '../../Switch';

import { CurrentSummarySegment } from './hooks/useSummaryData';
import { BuyableType, SummaryMode } from './utils/summaryUtils';

type SideMenuSummaryProps = {
  chapters?: Chapter[];
  subjectColor?: string;
  videosActice: boolean;
  handleOptionChange: (option: SummaryMode) => void;
  onChapterOrSectionPress: (section: Section) => void;
  isNavActive: boolean;
  summaryMode: SummaryMode;
  current: CurrentSummarySegment;
};

export const SideMenuSummary = ({
  chapters,
  subjectColor,
  videosActice,
  handleOptionChange,
  onChapterOrSectionPress,
  isNavActive,
  summaryMode,
  current,
}: SideMenuSummaryProps) => {
  const t = useTranslations();
  const { encodedSubjectName } = useParams() || {};
  const { showModal } = useModal();

  const { data: sections } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );
  const { data: summary } = useGetSummaryByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );
  const { previewSummary, previewExercise } = sections || {};

  const isChapterLocked = (section: Section) =>
    previewSummary && previewExercise && !section.preview;

  const onChapterSectionClicked = (chapterSection: Section) => {
    if (isChapterLocked(chapterSection)) {
      showModal({
        type: ModalTypes.BuySectionModal,
        data: {
          encodedSubjectName,
          buyableType: BuyableType.Summary,
        },
      });
      return;
    }
    onChapterOrSectionPress(chapterSection);
  };

  const openAnswersInNewTab = () => {
    if (summary?.answers) {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}${summary.answers}`,
        '_blank'
      );
    }
  };

  return (
    <MenuContainer isNavActive={isNavActive}>
      <HeaderMocker />
      <ScrollContainer>
        {videosActice && (
          <MultiSwitch
            backgroundColor={subjectColor}
            onSelectedChange={handleOptionChange}
            summaryMode={summaryMode}
          />
        )}

        {chapters?.map((chapter, i) => {
          return (
            <ChaptersContainer key={`chapter-${i}`}>
              <ChapterTitle>{chapter.chapterTitle}</ChapterTitle>
              <SectionsContentContainer>
                {chapter.sections.map((chapterSection, j) => {
                  return (
                    <SectionsContainer key={`section-${j}`}>
                      <ChapterItemContainer
                        onClick={() => onChapterSectionClicked(chapterSection)}
                      >
                        <ChapterItem
                          isItemSelected={
                            chapterSection.sectionTitle ===
                            current.section.sectionTitle
                          }
                          subjectColor={subjectColor}
                        >
                          {chapterSection.sectionTitle}
                        </ChapterItem>
                        <ChapterRating
                          isItemSelected={
                            chapterSection.sectionTitle ===
                            current.section.sectionTitle
                          }
                          subjectColor={subjectColor}
                        >
                          {chapterSection.sectionRating}
                        </ChapterRating>
                      </ChapterItemContainer>
                    </SectionsContainer>
                  );
                })}
              </SectionsContentContainer>
            </ChaptersContainer>
          );
        })}
        {summary?.answers && (
          <AnswersButton buttonSize="md" onClick={openAnswersInNewTab}>
            <ButtonTitle>{t('see_answers')}</ButtonTitle>
          </AnswersButton>
        )}
      </ScrollContainer>
    </MenuContainer>
  );
};

const MenuContainer = styled.div<{ isNavActive: boolean }>`
  background-color: ${Colors.backgroundColor};
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  z-index: ${ZIndex.z10};

  transition: all 0.5s ease;
  transform: ${({ isNavActive }) =>
    isNavActive ? 'translateX(0%)' : 'translateX(-100%)'};

  @media ${HookMediaQueries.isDesktop} {
    width: ${SUMMARY_SIDE_MENU_WIDTH};
  }
`;
const ScrollContainer = styled.div`
  padding: 20px;
`;

const HeaderMocker = styled.div`
  height: calc(${SUMMARY_HEADER_HEIGHT} + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${Colors.backgroundColor};
  z-index: ${ZIndex.z20};
  border-bottom: 1px solid ${Colors.dividerColor};
  box-sizing: border-box;

  @media ${HookMediaQueries.isDesktop} {
    width: ${SUMMARY_SIDE_MENU_WIDTH};
  }
`;

const ChapterItemContainer = styled.div`
  cursor: pointer;
  gap: 8px;
  word-wrap: break-word;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChapterItem = styled.span<{
  isItemSelected: boolean;
  subjectColor?: string;
}>`
  color: ${(props) =>
    props.isItemSelected ? props.subjectColor : Colors.primaryTextColor};
  white-space: normal;
  word-break: break-word;
  ${TextStyles.ListItemSubtitle}
`;

const ChapterRating = styled.span<{
  isItemSelected: boolean;
  subjectColor?: string;
}>`
  color: ${(props) =>
    props.isItemSelected ? props.subjectColor : Colors.primaryTextColor};
  white-space: normal;
  word-break: break-word;
  ${TextStyles.ListItemSubtitle};
  text-wrap: nowrap;
`;
const ChapterTitle = styled.span`
  overflow: auto;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;

const AnswersButton = styled(SecondaryButton)`
  padding: 4px 12px;
`;

const ButtonTitle = styled.span`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ButtonTextExtraSmallText}
`;

const ChaptersContainer = styled.div`
  margin: 20px 0px;
`;
const SectionsContainer = styled.div`
  margin: 5px 0px;
`;
const SectionsContentContainer = styled.div`
  margin: 10px 0px;
`;
