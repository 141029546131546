import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import femaleTeacherImage from '../../../../assets/images/female-teacher-pointing-with-finger-at-mathematica.jpg';
import { ActionSubjectCard } from '../../../../components/ActionSubjectCard';
import { VideoActionSubjectCard } from '../../../../components/VideoActionSectionCard';
import { HookMediaQueries } from '../../../../constants/MediaQueries';
import { ModalTypes, useModal } from '../../../../hooks/useModal';
import { Section, Sections } from '../../../../models/Summary/summary';
import { BuyableType } from '../../../../models/training/buyable';
import { CrownIcon } from '../../../Icons/Crown';
import { History } from '../../../Icons/History';
import { useSectionActions } from '../useSectionActions';
import { useSubjectHome } from '../useSubjectHome';

type LearnNextCardProps = {
  subjectColor?: string;
  sections?: Sections;
  encodedSubjectName: string;
  progressPercentageTraining: number;
  progressPercentageSummary: number;
};

export const LearnNextCard = ({
  subjectColor,
  sections,
  encodedSubjectName,
  progressPercentageTraining,
  progressPercentageSummary,
}: LearnNextCardProps) => {
  const t = useTranslations();
  const { openSummary, openVideo, openTraining } = useSectionActions();
  const {
    firstPracticeSectionAvailable,
    firstUntrainedPassedSection,
    examTrainingVideosEnabled,
    nextVideo,
    firstUnreadSection,
    openFreeTraining,
  } = useSubjectHome({
    encodedSubjectName,
  });

  const { showModal } = useModal();

  // TODO https://digitalclub.atlassian.net/browse/EXO-4043

  const learningText = `${progressPercentageSummary > 0 ? t('continue') : t('start')} ${t('learning_text')}`;
  const repeatLearningText = t('again_learning');
  const titleText =
    progressPercentageSummary === 1 ? repeatLearningText : learningText;

  const trainingText = `${progressPercentageTraining > 0 ? t('continue') : t('start')} ${t('training_text')}`;
  const repeatTrainingText = t('again_training');
  const trainingTitleText =
    progressPercentageTraining !== 1 ? repeatTrainingText : trainingText;

  const getEncodedChapterNameFromSection = (section: Section) => {
    if (!sections) return '';
    const chapter = sections.chapters.find((chapter) =>
      chapter.sections.some(
        (chapterSection) => section._id === chapterSection._id
      )
    );
    return chapter?.encodedChapterName || '';
  };

  const onTrainingClick = () => {
    const section =
      firstUntrainedPassedSection || firstPracticeSectionAvailable();
    if (!section) return;
    openTraining({
      section,
      encodedChapterName: getEncodedChapterNameFromSection(section),
    });
  };

  const onVideoActionSubjectCardPress = () => {
    if (!firstUnreadSection) return;
    if (sections?.unlockedExplanationVideos) {
      openVideo(firstUnreadSection);
      return;
    }

    showModal({
      type: ModalTypes.BuySectionModal,
      data: {
        encodedSubjectName,
        buyableType: BuyableType.videos,
      },
    });
  };

  return (
    <Container>
      <StyledColumn>
        <ActionSubjectCard
          subjectColor={subjectColor}
          onPress={() => firstUnreadSection && openSummary(firstUnreadSection)}
          title={titleText}
          subTitle={firstUnreadSection?.sectionTitle}
        />
        {sections?.videosActive && (
          <VideoActionSubjectCard
            onPress={onVideoActionSubjectCardPress}
            subjectColor={subjectColor}
            tagTitle={t('common_explanation')}
            backgroundImage={nextVideo?.thumbnailUrl}
            iconVariation={<CrownIcon />}
            highlightedText={t('common_buy')}
            regularText={t('subject_card_video')}
          />
        )}
      </StyledColumn>
      <StyledColumn>
        {sections?.practiceModuleActive && firstPracticeSectionAvailable() && (
          <ActionSubjectCard
            subjectColor={subjectColor}
            onPress={onTrainingClick}
            title={trainingTitleText}
            subTitle={firstUntrainedPassedSection?.sectionTitle}
          />
        )}
        {examTrainingVideosEnabled && (
          <VideoActionSubjectCard
            onPress={openFreeTraining}
            subjectColor={subjectColor}
            tagTitle={t('common_repeat')}
            backgroundImage={femaleTeacherImage}
            iconVariation={<History />}
            highlightedText={t('common_look')}
            regularText={t('back_to_livestream')}
          />
        )}
      </StyledColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
  }
`;
const StyledColumn = styled.div<{
  flex?: number;
  margin?: string;
}>`
  flex: ${(props) => props.flex || 1};
  margin: ${(props) => props.margin};
  padding: 0 20px;

  @media ${HookMediaQueries.isDesktop} {
    padding: 0;
  }
`;
