import { IconProps, Icon } from './Icon';

export const LockIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M4.99967 18.3335C4.54134 18.3335 4.14898 18.1703 3.82259 17.8439C3.4962 17.5175 3.33301 17.1252 3.33301 16.6668V8.3335C3.33301 7.87516 3.4962 7.4828 3.82259 7.15641C4.14898 6.83002 4.54134 6.66683 4.99967 6.66683H5.83301V5.00016C5.83301 3.84739 6.23926 2.86475 7.05176 2.05225C7.86426 1.23975 8.8469 0.833496 9.99967 0.833496C11.1525 0.833496 12.1351 1.23975 12.9476 2.05225C13.7601 2.86475 14.1663 3.84739 14.1663 5.00016V6.66683H14.9997C15.458 6.66683 15.8504 6.83002 16.1768 7.15641C16.5031 7.4828 16.6663 7.87516 16.6663 8.3335V16.6668C16.6663 17.1252 16.5031 17.5175 16.1768 17.8439C15.8504 18.1703 15.458 18.3335 14.9997 18.3335H4.99967ZM9.99967 14.1668C10.458 14.1668 10.8504 14.0036 11.1768 13.6772C11.5031 13.3509 11.6663 12.9585 11.6663 12.5002C11.6663 12.0418 11.5031 11.6495 11.1768 11.3231C10.8504 10.9967 10.458 10.8335 9.99967 10.8335C9.54134 10.8335 9.14898 10.9967 8.82259 11.3231C8.4962 11.6495 8.33301 12.0418 8.33301 12.5002C8.33301 12.9585 8.4962 13.3509 8.82259 13.6772C9.14898 14.0036 9.54134 14.1668 9.99967 14.1668ZM7.49967 6.66683H12.4997V5.00016C12.4997 4.30572 12.2566 3.71544 11.7705 3.22933C11.2844 2.74322 10.6941 2.50016 9.99967 2.50016C9.30523 2.50016 8.71495 2.74322 8.22884 3.22933C7.74273 3.71544 7.49967 4.30572 7.49967 5.00016V6.66683Z"
      fill={color}
    />
  </Icon>
);
